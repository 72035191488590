<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item to="/growth_val">成长值配置</el-breadcrumb-item>
            <el-breadcrumb-item>会员活跃策略配置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row>
         <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
            <span>会员活跃策略配置</span>
         </el-link>
      </el-row>
      <el-row class="cont-box">
         <el-row class="box">
            <el-row>
               <el-row class="top">
                  签到
                  <el-switch class="top-switch" v-model="signIn.isEnable" active-value="YES" inactive-value="NO" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
               </el-row>
               <el-row class="btm">
                  <el-row class="flex" style="padding-left: 40px">
                     <p>基本规则 <b style="color: red">*</b> 每日签到增加</p>
                     <el-input  class="width-120" v-model="signIn.baseValue"></el-input>
                     <p>成长值</p>
                  </el-row>
                  <el-row class="flex" style="padding-left: 20px">
                     <p>额外奖励规则</p>
                     <el-button  @click="appendSignIn">添加奖励规则</el-button>
                     <span>注：最多设置5个额外奖励规则，多个奖励不叠加</span>
                  </el-row>
                  <el-row class="flex" style="padding-left: 60px" v-for="(item, index) of signIn.extraList" :key="index">
                     <p>连续签到满</p>
                     <el-input  class="width-120" v-model="item.continueDay"></el-input>
                     <p>次，额外增加</p>
                     <el-input  class="width-120" v-model="item.extraValue"></el-input>
                     <p>成长值</p>
                     <el-button type="text"  class="m-left-30" @click="removeSignIn(index)">删除</el-button>
                  </el-row>
               </el-row>
            </el-row>
            <el-row>
               <el-row class="top">
                  分享
                  <el-switch class="top-switch" v-model="share.isEnable" active-value="YES" inactive-value="NO" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
               </el-row>
               <el-row class="btm">
                  <el-row class="flex" style="padding-left: 40px">
                     <p>基本规则 <b style="color: red">*</b> 每次分享增加</p>
                     <el-input  class="width-120" v-model="share.baseValue"></el-input>
                     <p>成长值</p>
                  </el-row>
                  <el-row class="flex" style="padding-left: 40px">
                     <p>限制规则 每人每天最多可获得</p>
                     <el-input  class="width-120" v-model="share.extraValue"></el-input>
                     <p>次分享成长值</p>
                  </el-row>
               </el-row>
            </el-row>
            <el-row>
               <el-row class="top">
                  评价
                  <el-switch class="top-switch" v-model="comment.isEnable" active-value="YES" inactive-value="NO" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
               </el-row>
               <el-row class="btm">
                  <el-row class="flex" style="padding-left: 40px">
                     <p>基本规则 <b style="color: red">*</b> 每次评价增加</p>
                     <el-input  class="width-120" v-model="comment.baseValue"></el-input>
                     <p>成长值</p>
                  </el-row>
                  <el-row class="flex" style="padding-left: 40px">
                     <p>限制规则 每人每天最多可获得</p>
                     <el-input  class="width-120" v-model="comment.extraValue"></el-input>
                     <p>次评价成长值</p>
                  </el-row>
               </el-row>
            </el-row>
            <el-row>
               <el-row class="top">
                  领卡
                  <el-switch class="top-switch" v-model="memberCard.isEnable" active-value="YES" inactive-value="NO" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
               </el-row>
               <el-row class="btm">
                  <el-row class="flex" style="padding-left: 40px">
                     <p>基本规则 <b style="color: red">*</b> 领卡成功，赠送</p>
                     <el-input  class="width-120" v-model="memberCard.baseValue"></el-input>
                     <p>成长值</p>
                  </el-row>
               </el-row>
            </el-row>
         </el-row>
         <el-row class="m-top-15 m-bottom-30 box-btn">
            <el-button class="m-right-30" type="primary"  plain @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleSave"  v-text="$t('msg.save')">保存</el-button>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   export default {
      data(){
         return{
            signIn: {
               type: 'SIGN_IN',
               isEnable: 'YES',
               baseValue: '',
               extraList: []
            },
            share: {
               type: 'SHARE',
               isEnable: 'YES',
               baseValue: '',
               extraValue: ''
            },
            comment: {
               type: 'COMMENT',
               isEnable: 'YES',
               baseValue: '',
               extraValue: ''
            },
            memberCard: {
               type: 'MEMBER_CARD',
               isEnable: 'YES',
               baseValue: ''
            },
           hotelId:'',
            remark: '会员活跃策略是通过记录用户评论、签到等行为来累计客户成长值，进而评估会员等级',
            paramCfgList: [],
            save_success: '保存成功！'
         }
      },
      mounted() {
         this.hotelId = this.$route.query.hotelId
         this.weight = this.$route.query.weight
         this.id = this.$route.query.id
         if (this.id) {
            let paramCfg = this.$route.query.paramCfg.split('|')
            this.getActiveConf(paramCfg)
         }
      },
      methods: {
         // 获取活跃配置
         getActiveConf(paramCfg){
            paramCfg.forEach(item => {
               let itemCfg = JSON.parse(item)
               if (itemCfg.type === 'SIGN_IN'){
                  this.signIn = itemCfg
               }
               if (itemCfg.type === 'SHARE'){
                  this.share = itemCfg
               }
               if (itemCfg.type === 'COMMENT'){
                  this.comment = itemCfg
               }
               if (itemCfg.type === 'MEMBER_CARD'){
                  this.memberCard = itemCfg
               }
            })
         },
         // 追加签到规则
         appendSignIn(){
            if (this.signIn.extraList.length < 5) {
               this.signIn.extraList.push({continueDay: '', extraValue: ''})
            }
         },
         // 移除签到规则
         removeSignIn(index){
            this.signIn.extraList.splice(index, 1)
         },
         // 取消
         handleCancel(){},
         // 保存
         handleSave(){
            this.paramCfgList.push(this.signIn, this.share, this.comment, this.memberCard)
            let paramCfgList = this.paramCfgList.map(item => {
               return JSON.stringify(item)
            })
            const url = urlObj.saveGrowConfig
            const param = {
               id: this.id,
               growType: 'ACTIVITY',
               weight: this.weight,
               remark: this.remark,
               hotelId: this.hotelId,
               isEnable: this.$route.query.isEnable,
               paramCfgList
            }
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.save_success,
                     type: 'success'
                  })
                  this.goBack(-1)
               }
            })
         },
         // 返回上页
         goBack(){
            this.$router.go(-1)
         }
      }
   }
</script>

<style scoped lang="scss">
   .cont-box{
      padding-top: 30px;
      .box > div{
         margin-bottom: 10px;
         border: solid 1px #EBEEF5;
      }
      .top{
         padding: 10px 0 10px 15px;
         font-size: 15px;
         background-color: #f5f5f5;
         overflow: hidden;
         .top-switch{
            float: right;
            margin-right: 10px;
         }
      }
      .btm{
         font-size: 13px;
         padding-top: 20px;
         .flex{
            line-height: 28px;
            display: flex;
            p, .el-input, .el-button{
               margin: 0 0 20px 0;
            }
            span{
               color: #999999;
            }
            .el-input, .el-button{
               margin-left: 4px;
               margin-right: 4px;
            }
         }
      }
      .box-btn{
         padding-left: 30px;
      }
   }
</style>

